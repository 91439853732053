import { useEffect } from 'react';
import { App as AntdApp, ConfigProvider, Flex, Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';
import { LoadingState, ErrorState } from '../common';
import { actions } from '../../common';
import { RootState } from '../../common/store/state';
import { themeConfig } from '../../theme';
import { AppSteps } from '../app-steps';

const { Content } = Layout;
type Props = {
	dataSet: {
		apiNonce?: string;
	};
};

export const App = ({ dataSet }: Props) => {
	const {
		app: { isLoading, error },
	} = useSelector((state: RootState) => state);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.app.setApiNonce(dataSet.apiNonce));
		dispatch(actions.products.getProducts());
		dispatch(actions.userData.getUserData());
	}, [dispatch]);

	return (
		<ConfigProvider theme={themeConfig}>
			<AntdApp>
				<Flex gap='middle' wrap='wrap' className='app'>
					<Layout>
						<Content>
							{isLoading && <LoadingState />}
							{error && <ErrorState />}
							{!isLoading && !error && <AppSteps />}
						</Content>
					</Layout>
				</Flex>
			</AntdApp>
		</ConfigProvider>
	);
};
