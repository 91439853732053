import { Col, Row, Grid } from 'antd';
import { MealDetailsModal } from './meal-details-modal';
import { useState } from 'react';
import { EmptyState } from '../common/empty-state';

import './style.scss';
import { MealItem } from './meal-item';
import { getColSpan } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalSelectedQuantity } from './utils';
import { actions } from '../../common';
import { DeliverySlot, RootState } from '../../common/store/state';

const { useBreakpoint } = Grid;

export type Props = {
	deliverySlot: DeliverySlot;
};

export const MealsPicker = ({ deliverySlot }: Props) => {
	const {
		app: {
			data: { selectedProducts },
		},
		products: { data: products },
		userData: { data: userData },
	} = useSelector((state: RootState) => state);

	const dispatch = useDispatch();

	const onMealSelectionChange = (product: Product, quantity: number, reduce?: boolean) => {
		if (quantity < 0 || !userData) {
			return;
		}

		const selected = {
			...selectedProducts,
			[deliverySlot]: {
				...selectedProducts[deliverySlot],
				[product.id]: {
					quantity,
					id: product.id,
					image: product.image,
					deliverySlot,
				},
			},
		};

		const total = getTotalSelectedQuantity(selected);

		if (!reduce && total > userData.numberOfMeals) {
			console.log('max reached');
			return;
		}

		dispatch(actions.app.updateSelectedProducts(selected));
	};

	const [showDetailsFor, setShowDetailsFor] = useState<Product | null>(null);
	const screens = useBreakpoint();
	const colSpan = getColSpan(screens);

	if (!userData || products.length === 0) {
		return <EmptyState />;
	}

	return (
		<div className='ui-meals-picker-wrapper'>
			<MealDetailsModal
				product={showDetailsFor}
				isOpen={!!showDetailsFor}
				onClose={() => setShowDetailsFor(null)}
			/>
			<Row gutter={[16, 16]}>
				{products.map((item, index) => (
					<Col key={index} span={colSpan}>
						<MealItem
							maxReached={getTotalSelectedQuantity(selectedProducts) >= userData.numberOfMeals}
							item={item}
							onClick={setShowDetailsFor}
							onChange={onMealSelectionChange}
							quantity={selectedProducts[deliverySlot][item.id]?.quantity || 0}
						/>
					</Col>
				))}
			</Row>
		</div>
	);
};
