export type DeliverySlot = 'Wed' | 'Sun';
export type SelectedProductsBySlot = {
	Wed: SelectedProducts;
	Sun: SelectedProducts;
};

export type AppData = {
	deliverySlot?: DeliverySlot | 'Both';
	selectedProducts: SelectedProductsBySlot;
};

export type RootState = {
	app: {
		apiNonce?: string;
		isLoading: boolean;
		error: boolean;
		currentStep: number;
		data: AppData;
	};
	userData: {
		isLoading: boolean;
		error: boolean;
		data?: UserData;
	};
	products: {
		isLoading: boolean;
		error: boolean;
		data: Product[];
		selectedProducts: SelectedProducts;
	};
	order: {
		isLoading: boolean;
		error: boolean;
		response?: OrderResponse;
	};
};

export const initialState: RootState = {
	app: {
		apiNonce: '',
		isLoading: true,
		error: false,
		currentStep: 0,
		data: {
			selectedProducts: {
				Wed: {},
				Sun: {},
			},
		},
	},
	userData: {
		isLoading: false,
		error: false,
		data: undefined,
	},
	products: {
		isLoading: false,
		error: false,
		data: [],
		selectedProducts: {},
	},
	order: {
		isLoading: false,
		error: false,
	},
};
