import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../../store/state';

const sliceName = 'userData';

export const types = {
	INIT_APP: `${sliceName}/initApp`,
	GET_USER_DATA: `${sliceName}/getUserData`,
	GET_USER_DATA_COMPLETE: `${sliceName}/getUserDataComplete`,
};

export const userDataSlice = createSlice({
	name: sliceName,
	initialState: initialState.userData,
	reducers: {
		getUserData: (state) => {
			state.isLoading = true;
			state.error = false;
		},
		getUserDataComplete: (state, action) => {
			state.isLoading = false;
			state.error = false;
			state.data = action.payload;
		},
	},
});

export const actions = userDataSlice.actions;
export const reducer = userDataSlice.reducer;
