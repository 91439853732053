import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../../store/state';

const sliceName = 'products';

export const types = {
	GET_PRODUCTS: `${sliceName}/getProducts`,
	GET_PRODUCTS_COMPLETE: `${sliceName}/getProductsComplete`,
	UPDATE_SELECTED_PRODUCTS: `${sliceName}/updateSelectedProducts`,
};

export const productSlice = createSlice({
	name: sliceName,
	initialState: initialState.products,
	reducers: {
		getProducts: (state) => {
			state.isLoading = true;
			state.error = false;
		},
		getProductsComplete: (state, action) => {
			state.isLoading = false;
			state.error = false;
			state.data = action.payload;
		},
		updateSelectedProducts: (state, action) => {
			state.selectedProducts = action.payload;
		},
	},
});

export const actions = productSlice.actions;
export const reducer = productSlice.reducer;
