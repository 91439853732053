import { Button, Modal } from 'antd';

type Props = {
	product?: Product | null;
	isOpen: boolean;
	onClose: () => void;
};

export const MealDetailsModal = ({ product, isOpen, onClose }: Props) => {
	if (!product) {
		return null;
	}

	const { name, description } = product;

	return (
		<Modal
			onCancel={onClose}
			open={isOpen}
			title={<div dangerouslySetInnerHTML={{ __html: name }} />}
			footer={[
				<Button key='close' onClick={onClose}>
					Close
				</Button>,
			]}
		>
			<div dangerouslySetInnerHTML={{ __html: description }} />
		</Modal>
	);
};
