import { Card, InputNumber, Button, Segmented, Divider } from 'antd';
import emptyImage from '../../../assets/empty-image.png';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

const { Meta } = Card;

export type Props = {
	item: Product;
	quantity: number;
	onClick: (item: Product) => void;
	onChange: (item: Product, quantity: number, reduce?: boolean) => void;
	maxReached: boolean;
};

export const MealItem = ({ item, quantity, onClick, onChange, maxReached }: Props) => {
	return (
		<Card
			hoverable
			cover={<img onClick={() => onClick(item)} alt={item.name} src={item.image || emptyImage} />}
			actions={[
				<Button
					aria-label='Decrease quantity'
					icon={<ArrowLeftOutlined />}
					key={`pless-${item.id}`}
					shape='circle'
					type='primary'
					size='large'
					onClick={() => onChange(item, quantity - 1, true)}
					disabled={quantity === 0}
				/>,
				<InputNumber
					className='product-number-input'
					key={`pinput-${item.id}`}
					min={0}
					max={100}
					value={quantity}
					onChange={(value) => onChange(item, value as number)}
					size='large'
				/>,
				<Button
					aria-label='Increase quantity'
					icon={<ArrowRightOutlined />}
					key={`pmore-${item.id}`}
					shape='circle'
					type='primary'
					size='large'
					onClick={() => onChange(item, quantity + 1)}
					disabled={maxReached}
				/>,
			]}
		>
			<Meta
				title={
					<div onClick={() => onClick(item)} dangerouslySetInnerHTML={{ __html: item.name }} />
				}
				description={
					<>
						<Button size='small' onClick={() => onClick(item)}>
							View details
						</Button>
						{/* <Segmented aria-label='Delivery day' options={['Monday', 'Friday']} /> */}
					</>
				}
			/>
		</Card>
	);
};
