import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../../store/state';

const sliceName = 'order';

export const types = {
	CREATE_ORDER: `${sliceName}/createOrder`,
};

export const orderSlice = createSlice({
	name: sliceName,
	initialState: initialState.order,
	reducers: {
		createOrder: (state) => {
			state.isLoading = true;
			state.error = false;
			state.response = undefined;
		},
		createOrderComplete: (state, action) => {
			state.isLoading = false;
			state.error = action.payload.error;
			state.response = action.payload.response;
		},
	},
});

export const actions = orderSlice.actions;
export const reducer = orderSlice.reducer;
