/* eslint-disable react/no-unescaped-entities */
import { Typography, Flex, Grid, Space, Badge, Avatar, Button, Collapse } from 'antd';
import emptyImage from '../../../../assets/empty-image.png';

import './style.scss';
import { RootState } from '../../../../common/store/state';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalSelectedQuantity } from '../../../meals-picker/utils';
import { actions } from '../../../../common';
import { ArrowRightOutlined, CaretRightOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;
const { Text } = Typography;

export const ChooseMealsController = () => {
	const {
		userData: { data: userData },
		app: {
			currentStep,
			data: { selectedProducts, deliverySlot },
		},
	} = useSelector((state: RootState) => state);
	const dispatch = useDispatch();

	const screens = useBreakpoint();

	if (!userData) {
		return null;
	}

	const totalSelectedQuantity = getTotalSelectedQuantity(selectedProducts || {});
	const isAllSelected = totalSelectedQuantity === userData.numberOfMeals;
	const shouldNextButtonBeDisabled =
		currentStep === 1 && deliverySlot === 'Both' && totalSelectedQuantity > 0
			? false
			: !isAllSelected;
	const nextButtonLabel =
		currentStep === 1 && deliverySlot === 'Both'
			? 'Select your Wednesday meals'
			: isAllSelected
			? 'View summary'
			: `Select all ${userData.numberOfMeals} meals`;

	return (
		<div
			className='ui-app-steps-choose-meals-controller'
			data-small-screen={!screens.md}
			data-visible={totalSelectedQuantity > 0}
		>
			<div className='ui-app-steps-choose-meals-controller-inner'>
				<Space direction='vertical' size={20} style={{ width: '100%' }}>
					<Text strong className='ui-app-steps-choose-meals-controller-span'>
						You've selected {totalSelectedQuantity} meal{totalSelectedQuantity > 1 ? 's' : ''} out
						of {userData.numberOfMeals}
					</Text>

					<Button
						type='primary'
						disabled={shouldNextButtonBeDisabled}
						size='large'
						icon={<ArrowRightOutlined />}
						onClick={() => dispatch(actions.app.goNext())}
					>
						{nextButtonLabel}
					</Button>
				</Space>
			</div>
		</div>
	);
};
