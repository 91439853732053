import { call, put, takeEvery, select } from 'redux-saga/effects';

import { actions, types } from './user-data.slice';
import { getUserData } from '../../../services/get-user-data';
import { RootState } from '../../store/state';

export const fetchUserData = function* (): Generator {
	const apiNonce = ((yield select((state: RootState) => state.app.apiNonce)) as string) || '';
	yield put(actions.getUserDataComplete(yield call(getUserData, { apiNonce })));
};

export function* userData() {
	yield takeEvery(types.GET_USER_DATA, fetchUserData);
}
