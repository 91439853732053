import { Action, Dispatch, Middleware } from '@reduxjs/toolkit';
import { types } from '../index';
import { RootState } from '../store/state';

export const checkAppStateMiddleware: Middleware<object, RootState, Dispatch<Action>> =
	(store) => (next) => (action) => {
		next(action);

		if (
			[types.products.GET_PRODUCTS_COMPLETE, types.userData.GET_USER_DATA_COMPLETE].includes(
				(action as Action).type
			)
		) {
			const { userData, products } = store.getState();

			if (userData.error || products.error) {
				store.dispatch({
					type: types.app.UPDATE_APP_STATE,
					payload: { isLoading: false, error: true },
				});
			} else if (!userData.isLoading && !products.isLoading) {
				store.dispatch({
					type: types.app.UPDATE_APP_STATE,
					payload: { isLoading: false, error: false },
				});
			}
		}
	};
