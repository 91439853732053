export const steps = [
	{
		title: 'Delivery slots',
		header:
			'Do you want to receive your meals on Sunday, Wednesday, or maybe split them between both days?',
		description: '',
	},
	{
		title: 'Choose meals',
		header: (
			<>
				Choose your <b>Sunday</b> meals
			</>
		),
		description:
			'Choose your favorite meals from our broad selection of available meals and enjoy delicious home-cooked meals all week long!',
	},
	{
		title: 'Choose meals',
		header: (
			<>
				Choose your <b>Wednesday</b> meals
			</>
		),
		description:
			'Choose your favorite meals from our broad selection of available meals and enjoy delicious home-cooked meals all week long!',
	},
	{
		title: 'Confirmation',
		header: 'View and confirm your order',
		description: 'Confirm your order and enjoy your delicious home-cooked meals!',
	},
];
