import { call, put, select, takeEvery } from 'redux-saga/effects';

import { actions, types } from './order.slice';
import { createOrder } from '../../../services/create-order';
import { RootState } from '../../store/state';
import _ from 'lodash';

export const sendCreateOrder = function* (): Generator {
	const {
		app: { apiNonce, data },
	} = (yield select()) as RootState;

	if (!apiNonce || !data) {
		yield put(actions.createOrderComplete({ error: true }));
		return;
	}

	yield put(
		actions.createOrderComplete(
			yield call(createOrder, {
				order: {
					deliverySlot: data.deliverySlot || '',
					products: _(data.selectedProducts)
						.mapValues((d) => {
							return _(d)
								.map((v) => ({ id: v.id, quantity: v.quantity }))
								.value();
						})
						.value(),
				},
				apiNonce,
			})
		)
	);
};

export function* order() {
	yield takeEvery(types.CREATE_ORDER, sendCreateOrder);
}
