import { all } from 'redux-saga/effects';
import { products } from './products/product.saga';
import { userData } from './user/user-data.saga';
import { order } from './order/order.saga';

export const sagas = {
	products,
	userData,
	order,
};

export function* rootSaga() {
	yield all([products(), userData(), order()]);
}
