import { Button, Flex, Result } from 'antd';
import { useSelector } from 'react-redux';

import { RootState } from '../../../common/store/state';

export const FinalStep = () => {
	const {
		app: {
			data: { deliverySlot },
		},
	} = useSelector((state: RootState) => state);

	return (
		<Flex vertical>
			<Result
				status='success'
				title='Your order has been successfully submitted!'
				subTitle={
					deliverySlot === 'Both'
						? 'You will receive two deliveries, one on Sunday and one on Wednesday.'
						: `You will receive your delivery on ${
								deliverySlot === 'Sun' ? 'Sunday' : 'Wednesday'
						  }.`
				}
				extra={[
					<Button type='default' size='large' key='dashboard'>
						Back to dashboard
					</Button>,
				]}
			/>
		</Flex>
	);
};
