import { Button, Flex, Space, Steps, Typography } from 'antd';

import './style.scss';

import { steps } from '../../../common/constants';
import { RootState } from '../../../common/store/state';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { actions } from '../../../common';

const { Title, Paragraph } = Typography;

export const Header = () => {
	const { currentStep } = useSelector((state: RootState) => state.app);
	const dispatch = useDispatch();
	const currentAppStep = steps[currentStep || 0];

	return (
		<Flex vertical className='ui-header-flex'>
			{/* <Steps
				size='small'
				current={currentStep === 1 || currentStep === 2 ? 1 : currentStep}
				items={[
					{
						title: 'Delivery slots',
					},
					{
						title: 'Choose meals',
					},
					{
						title: 'Confirmation',
					},
				]}
				className='ui-header-steps'
			/> */}

			<Space direction='vertical' size={10}>
				<div data-is-visible={currentStep !== 0}>
					<Button
						type='default'
						icon={<ArrowLeftOutlined />}
						size='large'
						onClick={() => dispatch(actions.app.goBack())}
					>
						Go back
					</Button>
				</div>
				<Title level={3} className='ui-header-title' style={{ maxWidth: '700px' }}>
					{currentAppStep.header}
				</Title>
				<Paragraph>{currentAppStep.description}</Paragraph>
			</Space>
		</Flex>
	);
};
