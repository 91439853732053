import { call, put, takeEvery } from 'redux-saga/effects';

import { actions, types } from './product.slice';
import { getProducts } from '../../../services/get-products';

export const fetchProducts = function* (): Generator {
	yield put(actions.getProductsComplete(yield call(getProducts)));
};

export function* products() {
	yield takeEvery(types.GET_PRODUCTS, fetchProducts);
}
