import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './ui/app';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './common/store';

document.addEventListener('DOMContentLoaded', () => {
	const el = document.getElementById('meals-picker-app') as HTMLElement;
	const root = ReactDOM.createRoot(el);

	root.render(
		<React.StrictMode>
			<Provider store={store}>
				<App dataSet={el.dataset || {}} />
			</Provider>
		</React.StrictMode>
	);

	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	reportWebVitals();
});
