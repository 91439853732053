import { Button, Flex, Radio, RadioChangeEvent, Space, Typography } from 'antd';
import { RootState } from '../../../common/store/state';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../common';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Header } from '../header';

export const ChooseSlot = () => {
	const {
		data: { deliverySlot },
	} = useSelector((state: RootState) => state.app);
	const dispatch = useDispatch();

	const onDeliverySlotChange = (e: RadioChangeEvent) => {
		dispatch(actions.app.updateDeliverySlot(e.target.value));
	};

	const onNextClick = () => {
		dispatch(
			actions.app.updateCurrentStep(deliverySlot === 'Both' || deliverySlot === 'Sun' ? 1 : 2)
		);
	};

	return (
		<Flex vertical>
			<Header />
			<Space direction='vertical' size={50}>
				<Radio.Group
					buttonStyle='solid'
					size='large'
					value={deliverySlot}
					onChange={onDeliverySlotChange}
				>
					<Radio.Button value='Sun'>Sunday</Radio.Button>
					<Radio.Button value='Wed'>Wedensday</Radio.Button>
					<Radio.Button value='Both'>Both</Radio.Button>
				</Radio.Group>

				{deliverySlot && (
					<Button type='primary' size='large' icon={<ArrowRightOutlined />} onClick={onNextClick}>
						Choose your meals
					</Button>
				)}
			</Space>
		</Flex>
	);
};
