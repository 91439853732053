import { configureStore, ThunkAction, Action, Middleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { RootState, initialState } from './state';
import { rootReducer, rootSaga } from '..';
import { checkAppStateMiddleware } from '../middlewares';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(sagaMiddleware as Middleware)
			.concat(checkAppStateMiddleware),
	preloadedState: initialState,
});

export const saga = sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
