import { Flex } from 'antd';

import { RootState } from '../../../common/store/state';
import { useSelector } from 'react-redux';
import { Header } from '../header';
import { MealsPicker } from '../../meals-picker';
import { ChooseMealsController } from './choose-meals-controller';

export const ChooseMeals = () => {
	const {
		app: { currentStep },
	} = useSelector((state: RootState) => state);

	return (
		<Flex vertical>
			<Header />
			<MealsPicker deliverySlot={currentStep === 1 ? 'Sun' : 'Wed'} />
			<ChooseMealsController />
		</Flex>
	);
};
