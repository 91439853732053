import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../common';
import { RootState } from '../../../../common/store/state';

export const ConfirmButtonWithModal = () => {
	const [open, setOpen] = useState(false);
	const { isLoading, response, error } = useSelector((state: RootState) => state.order);

	const dispatch = useDispatch();

	const showModal = () => {
		setOpen(true);
	};

	const handleOk = () => {
		dispatch(actions.order.createOrder());
	};

	const handleCancel = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (response) {
			setOpen(false);

			if (response.type === 'success') {
				dispatch(actions.app.goNext());
			}
		}
	}, [dispatch, response]);

	return (
		<>
			<Button type='primary' onClick={showModal} size='large'>
				Confirm your order
			</Button>
			<Modal
				title='Confirm your order'
				cancelText='Close'
				okText='Confirm'
				open={open}
				onOk={handleOk}
				confirmLoading={isLoading}
				onCancel={handleCancel}
			>
				<p>
					Are you sure you want to confirm your order? You will not be able to change it afterwards
				</p>
			</Modal>
		</>
	);
};
