import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../../store/state';

const sliceName = 'app';

export const types = {
	UPDATE_APP_STATE: `${sliceName}/updateAppState`,
};

export const appSlice = createSlice({
	name: sliceName,
	initialState: initialState.app,
	reducers: {
		setApiNonce: (state, action) => {
			state.apiNonce = action.payload;
		},
		updateAppState: (state, action) => {
			state.isLoading = action.payload.isLoading;
			state.error = action.payload.error;
		},
		updateCurrentStep: (state, action) => {
			state.currentStep = action.payload;
		},
		updateDeliverySlot: (state, action) => {
			state.data.selectedProducts = {
				Wed: {},
				Sun: {},
			};
			state.data.deliverySlot = action.payload;
		},
		updateSelectedProducts: (state, action) => {
			state.data.selectedProducts = action.payload;
		},
		goBack: (state) => {
			const prevStepIndex =
				state.currentStep === 2 && state.data.deliverySlot === 'Both'
					? 1
					: state.currentStep === 3 &&
					  (state.data.deliverySlot === 'Both' || state.data.deliverySlot === 'Wed')
					? 2
					: state.currentStep === 3 && state.data.deliverySlot === 'Sun'
					? 1
					: 0;

			window.scrollTo(0, 0);
			state.currentStep = prevStepIndex;
		},
		goNext: (state) => {
			window.scrollTo(0, 0);
			state.currentStep =
				state.currentStep === 3
					? 4
					: state.currentStep === 2
					? 3
					: state.currentStep === 1 && state.data.deliverySlot === 'Both'
					? 2
					: 3;
		},
	},
});

export const actions = appSlice.actions;
export const reducer = appSlice.reducer;
