import axios from 'axios';
import { config } from '../common/constants';
import { mockUserData } from '../mocks/user';

export const getUserData = async ({ apiNonce }: { apiNonce: string }) => {
	try {
		const { root } = config.api;
		const { data }: { data: UserData } = await axios.get(`${root}sams-app/v1/get-user-data/`, {
			headers: {
				'X-WP-Nonce': apiNonce,
			},
		});

		return data.id !== 0 ? data : mockUserData;
	} catch (error) {
		return mockUserData;
	}
};
