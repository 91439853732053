import { Breakpoint } from 'antd';
import { DeliverySlot, SelectedProductsBySlot } from '../../common/store/state';

export const getColSpan = (screens: Partial<Record<Breakpoint, boolean>>) => {
	let colSpan = 6;

	if (screens.lg) {
		colSpan = 6;
	} else if (screens.md) {
		colSpan = 8;
	} else if (screens.sm) {
		colSpan = 12;
	} else if (screens.xs) {
		colSpan = 24;
	} else {
		colSpan = 6;
	}

	return colSpan;
};

export const getTotalSelectedQuantity = (
	selectedProducts: SelectedProductsBySlot,
	deliverySlot?: DeliverySlot
) => {
	if (!selectedProducts) {
		return 0;
	}

	if (deliverySlot) {
		return Object.values(selectedProducts[deliverySlot]).reduce(
			(acc, item) => acc + item.quantity,
			0
		);
	}

	return (
		Object.values(selectedProducts.Sun).reduce((acc, item) => acc + item.quantity, 0) +
		Object.values(selectedProducts.Wed).reduce((acc, item) => acc + item.quantity, 0)
	);
};
