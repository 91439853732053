import { combineReducers } from '@reduxjs/toolkit';
import { types as appTypes, reducer as appReducer, actions as appActions } from './app/app.slice';
import {
	types as productTypes,
	reducer as productReducer,
	actions as productActions,
} from './products/product.slice';
import {
	types as userTypes,
	reducer as userReducer,
	actions as userActions,
} from './user/user-data.slice';
import {
	types as orderTypes,
	reducer as orderReducer,
	actions as orderActions,
} from './order/order.slice';

export const reducers = {
	app: appReducer,
	products: productReducer,
	userData: userReducer,
	order: orderReducer,
};

export const types = {
	app: appTypes,
	products: productTypes,
	userData: userTypes,
	order: orderTypes,
};

export const actions = {
	app: appActions,
	products: productActions,
	userData: userActions,
	order: orderActions,
};

export const rootReducer = combineReducers(reducers);
