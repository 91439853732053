import axios from 'axios';
import { config } from '../common/constants';

export const getProducts = async () => {
	try {
		const { root } = config.api;
		const { data } = await axios.get(`${root}sams-app/v1/get-products/`);
		return data;
	} catch (error) {
		return [];
	}
};
