import { ThemeConfig } from "antd";

export const themeConfig: ThemeConfig = {
	token: {
		colorPrimary: '#8DC63F',
	},
	components: {
		Layout: {
			bodyBg: '#fff',
		},
		Segmented: {
			itemSelectedBg: '#555',
			itemSelectedColor: '#fff',
		},
	},
};
